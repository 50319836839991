import React from "react"
import {useEffect, useRef} from "react"

const mMultiply = (matrix: number[][], vector: number[]) => {
    const mm = matrix.map((ms, i) => ms.map(m => m * vector[i]))
    // return mm
    const length = mm[0]?.length
    return Array.from({length: length}, (_, i) => mm.reduce((sum, ms) => sum + ms[i], 0))
}

const Rx = (theta: number) => [[1, 0, 0], [0, Math.cos(theta), Math.sin(theta)], [0, -Math.sin(theta), Math.cos(theta)]]
const Ry = (theta: number) => [[Math.cos(theta), 0, -Math.sin(theta)], [0, 1, 0], [-Math.sin(theta), 0, Math.cos(theta)]]
const Rz = (theta: number) => [[Math.cos(theta), Math.sin(theta), 0], [-Math.sin(theta), Math.cos(theta), 0], [0, 0, 1]]

const draw = (ctx, x: number, y: number, z: number) => {
    ctx.beginPath();
    // ctx.arc(x, y, 1 + z / 10, 0, 2 * Math.PI);
    ctx.arc(x, y, .8 * (1 + 1 * z/1000), 0, 2 * Math.PI);
    ctx.fillStyle = localStorage.theme && localStorage.theme !== 'dark' ? '#343E7A99' : '#C5CEE899'
    ctx.fill();
}

const frameSkipRate = 3

interface IProps {
    className: string
}

function HeroSphere({...props}: IProps) {

    const width = 400
    const height = 400
    const circles = 25
    const nDots = 80
    const r = width / 2 - 10
    const speed = 1
    const cx = width / 2
    const cy = height / 2

    const size = { width: width, height: height }
    const canvasRef = useRef(null)
    const requestIdRef = useRef(null)

    let a = 0
    const renderFrame = () => {
        const ctx = canvasRef.current.getContext("2d")

        ctx.clearRect(0, 0, width, height)
        ctx.save()
        Array.from({length: circles}, (_, i) => {
            for(let theta = 0; theta < 2 * Math.PI; theta += (2 * Math.PI / nDots)){
                const x0: number = r * Math.cos(theta)
                const y0 = r * Math.sin(theta)
                const z0 = 1
                const ratio = i / circles
                // let x1: number = x0
                let [x1, y1, z1] = [x0, y0, z0]
                const rotations = [Rx, Ry, Rz]
                rotations.forEach(rOp => {
                    const rx = mMultiply(rOp(a + ratio * 2 * Math.PI), [x1, y1, z1])
                    x1 = rx[0]
                    y1 = rx[1]
                    z1 = rx[2]
                })
                // x1, y1, z1 = mMultiply(Rx(a + ratio * 2 * Math.PI), [x1, y1, z1])
                // x1, y1, z1 = mMultiply(Ry(a + ratio * 2 * Math.PI), [x1, y1, z1])
                // x1, y1, z1 = mMultiply(Rz(a + ratio * 2 * Math.PI), [x1, y1, z1])
                const x = x1 + cx
                const y = y1 + cy
                const z = z1 + r / 2
                draw(ctx, x, y, z)
            }
        })
        a += frameSkipRate * speed / 1000 // .004// .07
        ctx.restore()
    }

    let skip = 0
    const tick = () => {
        if (!canvasRef.current) return
        skip++
        if(skip % frameSkipRate === 0) renderFrame()
        requestIdRef.current = requestAnimationFrame(tick)
    }

    useEffect(() => {
        requestIdRef.current = requestAnimationFrame(tick)
        return () => {
            cancelAnimationFrame(requestIdRef.current)
        }
    }, [])

    return <canvas {...size} ref={canvasRef} className={props.className} />
}

export default HeroSphere