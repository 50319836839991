import {
  useGoogleReCaptcha
} from "react-google-recaptcha-v3";
import React, {useState, useEffect, useCallback} from "react";

const SubscribeForm = () => {
  const {executeRecaptcha} = useGoogleReCaptcha();

  const [inputs, setInputs] = useState({
    email: "",
    message: "",
    consent: false,
  });

  const [submitState, setSubmitState] = useState({
    submitted: false,
    submitting: false,
    info: {error: false, msg: null},
  });

  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setSubmitState({
      submitted: false,
      submitting: false,
      info: {error: false, msg: null},
    });
  };

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setSubmitState({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        email: '',
        message: '',
        consent: false,
      });
    } else {
      setSubmitState({
        info: { error: true, msg: msg },
      });
    }
  };


  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = async (event) => {
    event.preventDefault();
    if (!executeRecaptcha) {
      return false;
    }

    const token = await executeRecaptcha("formSubmit");
    handleToken(token);
    return false;
  };

  const handleToken = (token) => {
    fetch(
      "https://us-central1-platern-web.cloudfunctions.net/contacts",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          recaptchaToken: token,
          contact: inputs
        })
      })
      .then((response) => {
        handleServerResponse(
          response.status === 200,
          response.status === 200
            ? 'Thank you, your message has been submitted.'
            : 'Something went wrong',
        );
        // document.getElementById("contact-submit-loading").classList.add("hidden");
      })
      .catch((err) => {
        console.error(err);
        handleServerResponse(false, err.response?.data?.error);
        // document.getElementById("contact-submit-failed").classList.remove("hidden");
      });
  };

  return (
    !submitState.submitted ?
      <>
        <form id="subscribe-form" onSubmit={handleReCaptchaVerify}>
          <div>
            <input
              id="name"
              type="name"
              name="_replyto"
              className="text-lg w-80 px-4 py-2 rounded border"
              placeholder="Name"
              onChange={handleOnChange}
              required
              value={inputs.name || ""}
            />
          </div>
          <br/>
          <div>
            <input
              id="email"
              type="email"
              className="text-lg w-80 px-4 py-2 rounded border"
              disabled={submitState.submitting}
              name="_replyto"
              placeholder="Email"
              onChange={handleOnChange}
              required
              value={inputs.email || ""}
            />
          </div>
          <br/>
          <div>
            <input
              className="form-check-input checked:bg-cta-primary h-4 w-4 border border-gray-300 rounded-sm bg-white checked:text-accent focus:outline-none transition duration-200 mt-1 align-middle bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
              disabled={submitState.submitting}
              type="checkbox" onChange={handleOnChange} required
              value={inputs.consent.toString() || "false"}
              id="agree-subscribe"/>
            <label
              className="form-check-label inline-block text-base text-sm"
              htmlFor="agree-subscribe">
              I agree to receive Platern's newsletters and accept the data
              privacy
              statement.
            </label>
          </div>
          <label className="text-base text-xs">You may unsubscribe at any time using the
            link in
            our newsletter.
          </label>
          <br/>
          <br/>
          {/*<div id="g-recaptcha" ref={greCaptchaRef}></div>*/}
          <br/>
          <button
            type="submit"
            // data-sitekey="6Lc-NYwjAAAAADCimodaQCv7Y1YSUUr4R3xaM9jY"
            // data-callback='onSubmit'
            // data-action='submit'
            // onClick={handleReCaptchaVerify}
            className="m-auto bg-cta-primary text-cta-primary px-12 py-2 rounded relative origin-bottom-left g-recaptcha"
            disabled={submitState.submitting}>
            <svg
              id="contact-submit-loading"
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white hidden"
              xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12"
                      r="10" stroke="currentColor"
                      strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Subscribe
          </button>
        </form>

        {submitState?.info?.error ?
          <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert">
          <span
            className="block sm:inline">Something went wrong. Please try again.</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
          </span>
        </div>
          : <></>
        }
    </>
        :
      !submitState?.info?.error
        ? <div className="bg-teal-100 border border-teal-500 text-teal-900 px-4 py-3 rounded relative"  role="alert">
        <span
          className="block sm:inline"><strong className="font-bold">Thanks!</strong> Check your inbox to confirm your subscription.</span>
        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
        </span>
      </div>
        : <></>
  );

  // return <button type="submit"
  //   // data-sitekey="6Lc-NYwjAAAAADCimodaQCv7Y1YSUUr4R3xaM9jY"
  //   // data-callback='onSubmit'
  //   // data-action='submit'
  //                onClick={handleReCaptchaVerify}
  //                className="m-auto bg-cta-primary text-cta-primary px-12 py-2 rounded relative origin-bottom-left g-recaptcha"
  //                // disabled={status.submitting}
  // >
  // {/*return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;*/}
};

export default SubscribeForm;