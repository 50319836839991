import * as React from "react";

import Layout from "../components/sitewide/layout";
import Seo from "../components/sitewide/seo";
import FeatureDirect from "../components/images/feature-direct";
import FeatureSecure from "../components/images/feature-secure";
import FeatureFast from "../components/images/feature-fast";
import {Icon} from "@iconify/react";
import roundAutoAwesome from "@iconify-icons/ic/round-auto-awesome";
import "../components/home/home.css";
import HeroSphere from "../components/home/hero-sphere";
import CustomerJourneyDevices
  from "../components/images/customer-journey-devices";
import ConnectedCubes from "../components/images/connected-cubes";
import scrollTo from "gatsby-plugin-smoothscroll";
import SubscribeForm from "../components/home/subscribe-form";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

export default function IndexPage() {

  const loadScript = url => new Promise(resolve => {
    const tag = document.createElement("script");
    tag.async = false;
    tag.src = url;
    const body = document.body;
    body.appendChild(tag);
    tag.addEventListener("load", resolve, {
      once: true
    });
  });

  console.log(`
    @@@@@@@@@@@@%/#@@@@@@@@@@@@@@@@@@@@@@//@
    @@@@@@@@@@@@@&(///////%&&&&&&&#///////(@
    @@@@@@@@@@@@@@@%//////////////////////(@
    @@@@@@@@@@@@@@@@@(///////////////////(@@
    @@@@@@@@@@@@@@@@@@#((((((((((((((((((%@@
    @@@@@@@@@@@@@@@@@@@&((((((((((((((((&@@@
    @@@@@@@@@@@@@@@@@@@@@(((((((((((((#@@@@@
    @@@@@@@@@@@@@@@@@@@@@@%((((((((#&@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@#((((%@@@@@@@@@@
    /@@@@@@@@@@@@@@@@@@@@@@@(/&@@@@@@@@@@@@@
    ///////#%%%%%%%%%%%/////////%@@@@@@@@@@@@@@@@
    /////////////////////////////%@@@@@@@@@@
    @(////////////////////////////%@@@@@@@@@
    @%(/////////////////////////////#@@@@@@@
    @@@((((((((((((((((((((((((((((((#@@@@@@
    @@@@#(((((((((((((((((((((((((((((#@@@@@
    @@@@@&((((((((((((((((((((((((((((((#@@@
    @@@@@@@@#(((((((((((((((((((((((((((((@@
    @@@@@@@@@@@&##(((((((((((((((((((((((((@
    @@@@@@@@@@@@@@@@%%%((((((((((((((#%%&@@@@@
    
    Thinking about collaborating? Don't hesitate to get in touch: hello@platern.com
    
    `);

  return (
    <Layout>
      <Seo title="Platern"/>
      <section
        className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s text-center md:text-left">
        <div className="m-auto">
          <div
            className="m-auto md:inline-block md:w-1/2 md:p-0 md:align-middle">
            <div className="align-middle">
              <div
                className="mb-10">
                <div>
                  <h1
                    className="mb-4 text-primary text-5xl font-black leading-normal md:leading-snug">Connect
                    to banking currency accounts</h1>
                  {/*<p className="mb-10">In the UK, apps and websites authorised by <a className="text-primary" href="#">the FCA</a> help you to understand and control your finances with open banking.</p>*/}
                  {/*<p className="mb-6">We’re building a service to make fast bank transfers and read account data. Champion the customer experience by using our resources to connect directly to the open banking network.</p>*/}
                  <p className="mb-6">Use Platern Web to make <strong>cardless bank
                    transfers</strong> and <strong>read account data</strong>. Connect directly to the
                    open banking network with <strong>no maintenance necessary</strong>.
                    Start building intelligent products that
                    reduce costs, convert more and improve engagement today.</p>
                  <p className="mb-6">We're up and testing in the UK!</p>
                  <p className="mb-6">Subscribe to receive updates.</p>
                </div>
                <button
                  onClick={() => scrollTo("#subscribe")}
                  className="m-auto bg-cta-primary text-cta-primary px-12 py-2 rounded relative origin-bottom-left"
                >Stay informed - it's free
                </button>
              </div>
            </div>
          </div>
          <div
            className="m-auto md:inline-block md:w-1/2 md:p-0 md:align-middle hidden md:visible">
            <HeroSphere className="m-auto"/>
          </div>
        </div>
      </section>


      <section
        className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s">
        <div className="">
          <div
            className="md:flex md:flex-wrap w-full justify-around text-center">
            <div className="mb-8">
              <FeatureDirect className="m-auto"/>
              <p className="mt-8">No hidden parties</p>
            </div>
            <div className="mb-8">
              <FeatureSecure className="m-auto"/>
              <p className="mt-8">Highly secure</p>
            </div>
            <div className="mb-8">
              <FeatureFast className="m-auto"/>
              <p className="mt-8">Fast connections</p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s">
        <div className="m-auto">
          <div
            className="m-auto md:inline-block md:w-1/2 md:p-0 md:align-middle">
            <div className="align-middle">
              <div
                className="mb-10 align-middle">
                <h1 className="mb-4 text-primary">Perfect the customer
                  journey.</h1>
                <p className="mb-10">Our platform grants your product full
                  control over the customer journey, allowing you to do
                  important things like compliance and analytics.</p>
              </div>
            </div>
          </div>
          <div
            className="m-auto md:inline-block md:w-1/2 md:p-0 md:align-middle">
            <div className="m-auto w-full">
              <CustomerJourneyDevices className="m-auto"/>
            </div>
          </div>
        </div>
      </section>

      <section
        className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s">
        <div className="m-auto md:hidden">
          <div
            className="m-auto md:inline-block md:w-1/2 md:p-0 md:align-middle">
            <div className="align-middle">
              <div
                className="mb-10 align-middle">
                <h1 className="mb-4 text-primary">A lightweight open banking
                  framework.</h1>
                <p className="mb-10">Our modular tools reduce the complexity of
                  maintaining connections to banking APIs, providing you with
                  the flexibility and speed suitable for a wide variety of use
                  cases.</p>
              </div>
            </div>
          </div>
          <div
            className="m-auto md:inline-block md:w-1/2 md:p-0 md:align-middle">
            <div className="m-auto w-full">
              <ConnectedCubes className="m-auto"/>
            </div>
          </div>
        </div>
        <div className="m-auto hidden md:block">
          <div
            className="m-auto md:inline-block md:w-1/2 md:p-0 md:align-middle">
            <div className="m-auto w-full">
              <ConnectedCubes className="m-auto"/>
            </div>
          </div>
          <div
            className="m-auto md:inline-block md:w-1/2 md:p-0 md:align-middle">
            <div className="align-middle">
              <div
                className="mb-10 align-middle">
                <h1 className="mb-4 text-primary">A lightweight open banking
                  framework.</h1>
                <p className="mb-10">Our modular tools reduce the complexity of
                  maintaining connections to banking APIs, providing you with
                  the flexibility and speed suitable for a wide variety of use
                  cases.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="subscribe"
               className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s">
        <div
          className="m-auto text-center">
          <h1 className="mb-4 text-primary font-bold">Want to stay
            informed?&nbsp;<Icon className="inline-block hover:text-accent"
                                 icon={roundAutoAwesome}/></h1>
          <p className="mb-8">Join our community to receive occasional
            updates.</p>
          <GoogleReCaptchaProvider
            reCaptchaKey="6Lc-NYwjAAAAADCimodaQCv7Y1YSUUr4R3xaM9jY"
            // onVerify={token => {
            //   console.log("onVerify");
            //   handleServerResponse()
            // }}
          >
            <SubscribeForm/>
          </GoogleReCaptchaProvider>
        </div>
      </section>
    </Layout>
  );
}
