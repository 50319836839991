import React, {useEffect, useRef, useState} from 'react'

// import UseHover from "../../util/use-hover";

interface IProps {
    className: string
}

export default function ConnectedCubes({...props}: IProps) {

    const useHover = () => {
        const [value, setValue] = useState(false);
        const ref = useRef(null);
        const handleMouseOver = () => setValue(true);
        const handleMouseOut = () => setValue(false);
        useEffect(
            () => {
                const node = ref.current;
                if (node) {
                    node.addEventListener("mouseover", handleMouseOver);
                    node.addEventListener("mouseout", handleMouseOut);
                    return () => {
                        node.removeEventListener("mouseover", handleMouseOver);
                        node.removeEventListener("mouseout", handleMouseOut);
                    };
                }
            },
            // [ref.current]
        );
        return [ref, value];
    }
    const [ref, isHovered] = useHover()

    return (

        <svg ref={ref} className={props.className}
             viewBox = "0 0 347 248"
             width="100%" height="100%" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="m247.412 99.507-30.956-17.872L185.5 99.507m61.912 0v35.745l-30.956 17.872m30.956-53.617-30.956 17.872m0 35.745L185.5 135.252V99.507m30.956 53.617v-35.745M185.5 99.507l30.956 17.872"
                stroke="#CCCFDE" strokeWidth=".942"/>
            <path
                d="m278.484 81.469-30.956-17.873-30.956 17.873m61.912 0v35.744l-30.956 17.873m30.956-53.617L247.528 99.34m0 35.745-30.956-17.873V81.469m30.956 53.617V99.341m-30.956-17.872 30.956 17.872"
                stroke="#CCCFDE" strokeWidth=".942"/>
            <path
                d="m278.361 81.487-30.956-17.872-30.956 17.872m61.912 0v35.745l-30.956 17.872m30.956-53.617L247.405 99.36m0 35.744-30.956-17.872V81.487m30.956 53.617V99.36m-30.956-17.873 30.956 17.873"
                stroke="#CCCFDE" strokeWidth=".942"/>
            <path
                d="M309.416 63.597 278.46 45.724l-30.956 17.873m61.912 0V99.34l-30.956 17.873m30.956-53.617L278.46 81.469m0 35.745-30.956-17.873V63.597m30.956 53.617V81.469m-30.956-17.872 30.956 17.872"
                stroke="#CCCFDE" strokeWidth=".942"/>
            <path
                d="m309.224 99.525-30.956-17.872-30.956 17.872m61.912 0v35.745l-30.956 17.872m30.956-53.617-30.956 17.873m0 35.744-30.956-17.872V99.525m30.956 53.617v-35.744m-30.956-17.873 30.956 17.873"
                stroke="#CCCFDE" strokeWidth=".942"/>
            <path
                d="M278.484 45.872 247.528 28l-30.956 17.872m61.912 0v35.745l-30.956 17.872m30.956-53.617-30.956 17.873m0 35.744-30.956-17.872V45.872m30.956 53.617V63.745m-30.956-17.873 30.956 17.873"
                stroke="#CCCFDE" strokeWidth=".942"/>
            <path
                d="M309.416 99.489 278.46 81.617l-30.956 17.872m61.912 0v35.745l-30.956 17.872m30.956-53.617L278.46 117.36m0 35.745-30.956-17.872V99.489m30.956 53.617v-35.745m-30.956-17.872 30.956 17.872"
                stroke="#CCCFDE" strokeWidth=".942"/>
            <path
                d="m247.405 99.34 30.956 17.873v35.745l-30.956 17.872-15.478-8.936-15.478-8.936V117.213l30.956-17.872Z"
                fill="#596895"/>
            <path
                d="m278.361 117.213-30.956-17.872-30.956 17.872m61.912 0v35.745l-30.956 17.872m30.956-53.617-30.956 17.873m0 35.744v-35.744m0 35.744-15.478-8.936-15.478-8.936V117.213m0 0 15.478 8.936 15.478 8.937"
                stroke="#CCCFDE" strokeWidth=".942"/>
            <path
                d="m166.514 176.771 16.663-9.621 45.504-25.669a.549.549 0 1 1 .54.957l-46.044 25.995-16.663 9.62v-1.282Z"
                fill="#CCCFDE"/>
            <path
                d="m183.177 167.15-16.663 9.621v1.282l16.663-9.62m0-1.283v1.283m0-1.283 45.504-25.669a.549.549 0 0 1 .819.478.552.552 0 0 1-.279.479l-46.044 25.995"
                stroke="#CCCFDE" strokeWidth="6"/>
            <path
                d="m68.456 100 30.956 17.872V153.617l-30.956 17.872L37.5 153.617v-35.745L68.456 100Z"
                fill="#A3AFD3"/>
            <path
                d="M99.412 117.872 68.456 100 37.5 117.872m61.912 0-30.956 17.873m30.956-17.873V153.617l-30.956 17.872m0 0L37.5 153.617v-35.745m30.956 53.617v-35.744M37.5 117.872l30.956 17.873"
                stroke="#CCCFDE" strokeWidth=".942"/>
            <path
                d="m122.656 166.605-10.094-5.828-27.566-15.551a.333.333 0 0 0-.327.58l27.893 15.748 10.094 5.828v-.777Z"
                fill="#FFD53E"/>
            <path
                d="m112.562 160.777 10.094 5.828v.777l-10.094-5.828m0-.777v.777m0-.777-27.566-15.551a.333.333 0 0 0-.327.58l27.893 15.748"
                stroke="#FFD53E" strokeWidth="6"/>
            <path
                d="m154.047 149.002 30.955 17.872V202.619l-30.955 17.872-30.956-17.872v-35.745l30.956-17.872Z"
                fill="#ACDA9C"/>
            <path
                d="m185.002 166.874-30.955-17.872-30.956 17.872m61.911 0-30.955 17.873m30.955-17.873V202.619l-30.955 17.872m0 0-30.956-17.872v-35.745m30.956 53.617v-35.744m-30.956-17.873 30.956 17.873"
                stroke="#DCF7D3" strokeWidth=".942"/>
        </svg>

    )

}